import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import { seoTypes } from '../types/propTypes';

const SEO = ({ meta, page, noIndex }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { siteUrl } = site.siteMetadata;

  const modifiedMeta = { ...meta };

  // Check if the page number is greater than 1
  if (page && page > 1) {

    // Update the title
    const title = `${meta.tags[0].content} - Page ${page}`;

    if (!meta.tags[0].content.includes(`Page ${page}`)) {
      modifiedMeta.tags[0].content = title;
    }

    // Update the description
    const description = `${meta.tags[3].attributes.content} - Page ${page}`;

    if (!meta.tags[3].attributes.content.includes(`Page ${page}`)) {
      modifiedMeta.tags[3].attributes.content = description;
      modifiedMeta.tags[4].attributes.content = description;
      modifiedMeta.tags[5].attributes.content = description;
    }
    
  }

  return (
    <HelmetDatoCms seo={modifiedMeta} page={page} noIndex={noIndex}>

      <html lang="en" />
      <meta property="og:url" content={`${siteUrl}${pathname}`} />

      {process.env.GATSBY_NOINDEX === 'true' || process.env.NOINDEX === 'true' || noIndex ? (
        <meta name="robots" content="noindex" />
      ) : null}

      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/file-icon-vectors@1.0.0/dist/file-icon-vivid.min.css"
      />
    </HelmetDatoCms>
  );
};

SEO.propTypes = seoTypes;

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        sitemapPath
        title
        description: author
      }
    }
  }
`;
